// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'article-number': 'Artikelnummer',
    action: 'Aktion',
    'deployment-date': 'Bereitstellungsdatum',
    environment: 'Umgebung',
    error: 'Fehler',
    errors: 'Fehler',
    'import-errors-found': 'Es wurden Importfehler gefunden.',
    'instance-url': 'Instanz-URL',
    message: 'Nachricht',
    'requested-by': 'Angefordert von',
    status: 'Status',
    'scheduled-date': 'Geplantes Datum',
    version: 'Version',
    "changes": "Änderungen",
    "changes-in-version": "Änderungen in Version",
    success: 'Deployment erfolgreich',
    failed: 'Deployment fehlgeschlagen',
    "status-success": "erfolgreich",
    "status-failed": "Fehler",
    "status-failure": "Fehler",
    "status-pending": "Ausstehend",
    "status-scheduled": "Geplant",
    viewErrors: "Fehler anzeigen",
    cancel:"Abbrechen",
}
