import {t} from 'i18next'
import moment from 'moment-timezone'
import {Link} from 'react-router-dom'

import Pill from 'src/components/parts/Pill'
import Button from 'src/components/parts/Button'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import {getPillStatusColor} from 'src/utils/getPillStatusColor'
import NoDetailsToShow from 'src/components/parts/NoDetailsToShow'

import {useHooks} from './hooks'

export const ProductionDeploymentsTable = () => {
    const {
        prodDeployments,
        isLoadingProdDeployments,
        handleDeploymentError,
        handleCancelDeployment,
    } = useHooks()

    if (isLoadingProdDeployments) {
        return <LoadingSpinner/>
    }

    return (
        <div className='table-container max-h-[calc(100vh-240px)] overflow-y-auto'>
            <table className='w-full text-sm text-left bg-neutral-50'>
                <thead className='sticky top-0 text-sm uppercase bg-neutral-200'>
                <tr>
                    <th
                        scope='col'
                        className='w-36 min-w-36 px-4 py-3 text-gray-700 whitespace-nowrap'
                    >
                        {t('deployment-date', {ns: 'deployment'})}
                    </th>
                    <th
                        scope='col'
                        className='w-36 min-w-36 px-4 py-3 text-gray-700 whitespace-nowrap'
                    >
                        {t('scheduled-date', {ns: 'deployment'})}
                    </th>
                    <th
                        scope='col'
                        className='w-80 min-w-80 px-4 py-3 text-gray-700 whitespace-nowrap'
                    >
                        {t('instance-url', {ns: 'deployment'})}
                    </th>
                    <th
                        scope='col'
                        className='w-48 px-4 py-3 text-gray-700 whitespace-nowrap'
                    >
                        {t('requested-by', {ns: 'deployment'})}
                    </th>
                    <th
                        scope='col'
                        className='px-4 py-3 text-gray-700 whitespace-nowrap'
                    >
                        {t('version', {ns: 'deployment'})}
                    </th>
                    <th
                        scope='col'
                        className='px-8 py-5 text-gray-700 whitespace-nowrap'
                    >
                        {t('status', {ns: 'deployment'})}
                    </th>
                    <th
                        scope='col'
                        className='px-8 py-5 text-gray-700 whitespace-nowrap'
                    >
                        {t('action', {ns: 'deployment'})}
                    </th>
                </tr>
                </thead>
                <tbody>
                {prodDeployments && prodDeployments?.length > 0 ?
                    prodDeployments.map(
                        ({
                             deploymentUuid,
                             url,
                             deploymentStatus,
                             scheduledTime,
                             deploymentDate,
                             requestedBy,
                             message,
                             portfolioVersion,
                         }) => (
                            <tr
                                key={deploymentUuid}
                                className='group border-neutral-200 border-b-[1px] hover:bg-white'
                            >
                                <td className='w-40 max-w-40 px-8 py-4 whitespace-nowrap'>
                                    {moment(deploymentDate).format(
                                        'DD.MM.YYYY, h:mm A',
                                    )}
                                </td>
                                <td className='w-40 max-w-40 px-8 py-4 whitespace-nowrap'>
                                    {scheduledTime ?
                                        moment(scheduledTime).format(
                                            'DD.MM.YYYY, h:mm A',
                                        )
                                        : '-'}
                                </td>
                                <td className='w-96 max-w-96 px-8 py-4 whitespace-nowrap overflow-hidden overflow-ellipsis '>
                                    {url !== 'null' ?
                                        <Link target="SOLARPLANIT"
                                              to={`${url}`}
                                              className='text-info group-hover:underline'
                                        >
                                            {url}
                                        </Link>
                                        : '-'}
                                </td>
                                <td className='w-60 px-8 py-4 whitespace-nowrap overflow-hidden overflow-ellipsis'>
                                    {requestedBy ?? '-'}
                                </td>
                                <td className='w-40 max-w-40 px-8 py-4 whitespace-nowrap'>
                                    {portfolioVersion ? (
                                        <Link
                                            to={`/deployment/versions/${portfolioVersion}`}
                                            className="text-primary-dark hover:underline"
                                        >
                                            {portfolioVersion}
                                        </Link>
                                    ) : '-'}
                                </td>
                                <td className='w-40 max-w-40 min-w-40 px-8 py-4 whitespace-nowrap'>
                                    <Pill
                                        className={`w-fit h-6 text-white ${getPillStatusColor(deploymentStatus)}`}
                                        label={deploymentStatus}
                                    />
                                </td>
                                <td className='flex px-8 py-4 gap-2'>
                                    {message && (
                                        <Button
                                            label={t('viewErrors', {ns: 'deployment'})}
                                            variant='outlined'
                                            onClick={() =>
                                                handleDeploymentError(
                                                    deploymentUuid,
                                                )
                                            }
                                            className='py-[1px] px-[10px] text-xs !h-6 border-primary-red text-primary-red'
                                        />
                                    )}
                                    {deploymentStatus === 'Scheduled' && (
                                        <Button
                                            label={t('cancel', {ns: 'deployment'})}
                                            variant='contained'
                                            onClick={() =>
                                                handleCancelDeployment(
                                                    deploymentUuid,
                                                )
                                            }
                                            className='py-[1px] px-[10px] text-xs !h-6 bg-primary-red'
                                        />
                                    )}
                                </td>
                            </tr>
                        ),
                    )
                    : <tr>
                        <td colSpan={6}>
                            <NoDetailsToShow/>
                        </td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    )
}
