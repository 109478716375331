import {t as translate} from 'i18next'
import toast from 'react-hot-toast'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useAuthContext} from 'src/context/AuthProvider'
import ToastWidget from 'src/components/parts/ToastWidget'
import {PageInfo, Portfolio} from 'src/services/api/portfolioApi'
import ReferenceDataApi from 'src/services/api/referenceDataApi'
import {usePortfolioQuery} from 'src/services/hooks/usePortfolioQuery'
import {usePortfolioItemQuery} from 'src/services/hooks/usePortfolioItemQuery'
import {usePortfolioTypesQuery} from "../../services/hooks/usePortfolioTypesQuery.ts";

const statusOptions = [
    {
        id: 1,
        label: 'ACTIVE',
        value: true,
    },
    {
        id: 2,
        label: 'INACTIVE',
        value: false,
    },
]

export const useHooks = () => {
    const _useNavigate = useNavigate()
    const {publish} = ReferenceDataApi
    const {company} = useAuthContext()
    const companyId = company?.uuid

    const isSupplierCompany = company?.type === 'SUPPLIER'
    const [isShowPublishConfirmationModal, setIsShowPublishConfirmationModal] =
        useState(false)
    const [currentPage, setCurrentPage] = useState<number | undefined>(
        undefined,
    )
    const [perPage, setPerPage] = useState<number | undefined>(undefined)
    const [pageInfo, setPageInfo] = useState<PageInfo | undefined>(undefined)
    const [activeFilter, setActiveFilter] = useState<string>("all")
    const [sortField, setSortField] = useState<string>("")
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc")

    /**
     * Portfolio Management
     */
    const {updatePortfolioItem} = usePortfolioItemQuery({})
    const {portfolioData, isLoadingPortfolioData, refetchPortfolioData} =
        usePortfolioQuery({
            companyId: company?.uuid,
            page: currentPage,
            perPage,
            type: activeFilter,
            sortField,
            sortOrder
        })
    const portfolioItems = portfolioData?.portfolioItems || []

    /**
     * Portfolio Items filtering
     */
    const {portfolioTypes} =
        usePortfolioTypesQuery({
            companyId: company?.uuid
        })
    const types = new Set()
    if (portfolioTypes) {
        for (let k in portfolioTypes) {
            types.add(portfolioTypes[k])
        }
    }
    const portfolioItemTypes = [...types]
        .sort((a, b) => a.localeCompare(b))
        .map((value) => {
            return {id: value, label: value, type: value}
        })
    const filters = [
        {id: 'all', label: 'All', type: "all"},
        ...portfolioItemTypes,
    ]

    const filterByType = (type?: string) => {
        return portfolioItems
    }

    const filteredPortfolioItems = filterByType(activeFilter)

    const getCount = (type?: string) => {
        return -1
    }

    const handleSelectTab = (type?: string) => {
        setCurrentPage(1)
        setActiveFilter(type)
    }

    /**
     * Portfolio Items pagination
     */
    useEffect(() => {
        if (!portfolioData?.pagingInfo) return

        const {currentPage, pageCount, perPage, totalCount} =
            portfolioData?.pagingInfo

        setPageInfo({
            currentPage,
            perPage,
            totalCount,
            pageCount,
        })
    }, [portfolioData, activeFilter])

    const handleResetPage = (perPage: number) => {
        setCurrentPage(1)
        setPerPage(perPage)
    }

    useEffect(() => {
        document.body.classList.add('loading');
        refetchPortfolioData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perPage, currentPage, activeFilter, sortField, sortOrder])

    /**
     * Publish Reference Data
     */
    const publishReferenceData = async () => {
        setIsShowPublishConfirmationModal(false)

        if (companyId) {
            try {
                await publish({companyId})
                toast.success(
                    (t) => (
                        <ToastWidget
                            label={translate('publish-reference-data', {
                                ns: 'portfolio',
                            })}
                            message={translate('saved', {ns: 'portfolio'})}
                            handleClose={() => toast.dismiss(t.id)}
                        />
                    ),
                    {position: 'bottom-right', duration: Infinity},
                )
            } catch (error) {
                toast.error(
                    <ToastWidget
                        label={translate('publish-reference-data', {
                            ns: 'portfolio',
                        })}
                        message={error.message}
                    />,
                    {position: 'bottom-right'},
                )
            }
        }
    }

    /**
     * PortfolioItem Update functions
     */

    const handleSetStatus = async ({
                                       value,
                                       portfolioItemId,
                                       isRepresentant,
                                   }: {
        value: boolean
        portfolioItemId: string
        isRepresentant: boolean
    }) => {
        document.body.classList.add('loading');
        if (portfolioItemId && companyId) {
            if (isRepresentant && !value) {
                toast.error(translate('cannot-deactivate-representant', {ns: 'portfolio'}))
                return
            }
            try {
                await updatePortfolioItem({
                    companyId,
                    portfolioItemId,
                    data: {
                        active: value,
                    },
                })
            } catch (error: any) {
                toast.error(error.message)
            }
        }
    }


    const handleSetRepresentant = async ({
                                             portfolioItemId,
                                             portfolioItem,
                                         }: {
        portfolioItemId: string
        portfolioItem: Portfolio
    }) => {
        document.body.classList.add('loading');
        if (portfolioItemId && portfolioItem && companyId) {
            try {
                await updatePortfolioItem({
                    companyId,
                    portfolioItemId,
                    data: {
                        Repraesentant: !portfolioItem.Repraesentant,
                    },
                })
            } catch (error: any) {
                toast.error(error.message)
            }
        }
    }

    /**
     * Other Functions
     */
    const rollback = () => {
        console.log('Rollback to previous version.')
    }

    return {
        pageInfo,
        company,
        filters,
        activeFilter,
        statusOptions,
        filteredPortfolioItems,
        isLoadingPortfolioData,
        isShowPublishConfirmationModal,
        setCurrentPage,
        getCount,
        rollback,
        navigate: function (path: string) {
            document.body.classList.remove('loading')
            _useNavigate(path)
        },
        handleResetPage,
        handleSetStatus,
        handleSelectTab,
        publishReferenceData,
        handleSetRepresentant,
        setIsShowPublishConfirmationModal,
        isSupplierCompany,
        sortField,
        sortOrder,
        setSortField,
        setSortOrder
    }
}
