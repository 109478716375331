import instance from './axiosInstance'

export type PageInfo = {
    currentPage: number
    perPage: number
    pageCount: number
    totalCount: number
}

export type ReferenceData = {
    uuid: string
    '@class': 'string'
    Artikelnummer: string
    Gesperrt: boolean
    Lieferbar: boolean
    Name: string
    Repraesentant: boolean
    en: string
    de?: string
    extendInfo: string
}

export type Portfolio = {
    uuid: string
    active: boolean
    artikelNummer: string
    referenceData: ReferenceData
    Repraesentant: boolean
    changeStatus: string
}

type UpdatePortfolioParams = {
    companyId: string
    portfolioItemId: string
    data: {
        active?: boolean
        Repraesentant?: boolean
    }
}

export type PortfolioDataVersion = {
    version: string
    deploymentDate: string
}

type PortfolioChange = {
    uuid: string
    Artikelnummer: string
    Name: String
    status: string
    updatedFields: string[]
    activeChange: {
        field: string
        oldValue: boolean
        newValue: boolean
    }
    repraesentantChange: {
        field: string
        oldValue: boolean
        newValue: boolean
    }
}

function ucFirst(str: string): string {
    if (!str) { // Handles null, undefined, and empty strings
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const PortfolioApi = {
    get: async (params: {
        companyId: string
        portfolioItemId: string
    }): Promise<Portfolio> => {
        const {companyId, portfolioItemId} = params

        const config = {
            url: `/organizations/${companyId}/portfolio/${portfolioItemId}`,
            method: 'GET',
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(`Failed to fetch portfolio item: ${error.message}`)
        }
    },

    getVersion: async (params: {
        companyId: string
        portfolioItemId: string
        version: string
    }): Promise<Portfolio> => {
        const {companyId, portfolioItemId, version} = params

        const config = {
            url: `/organizations/${companyId}/portfolio/${portfolioItemId}/version/${version}`,
            method: 'GET',
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(`Failed to fetch portfolio item version: ${error.message}`)
        }
    },

    getAll: async ({
                       companyId,
                       perPage = 10,
                       page = 1,
                       type,
                       sortOrder,
                       sortField,
                   }: {
        companyId: string
        perPage?: number
        page?: number
        type?: string
        sortOrder?: string
        sortField?: string
    }): Promise<{ pagingInfo: PageInfo; portfolioItems: Portfolio[] }> => {
        let url = `/organizations/${companyId}/portfolio`;
        if (type && type !== "all") {
            url = `/organizations/${companyId}/portfolio/artikeltype/${type}`;
        }
        const config = {
            url: url,
            method: 'GET',
            params: {
                perPage,
                page,
                order: "desc" == sortOrder ? "DESCENDING" : "ASCENDING",
                sortBy: sortField ? ucFirst(sortField) : "Name",
            },
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(`Failed to fetch portfolio: ${error.message}`)
        }
    },

    update: async (params: UpdatePortfolioParams): Promise<Portfolio> => {
        const {companyId, portfolioItemId, data} = params

        const config = {
            url: `/organizations/${companyId}/portfolio/${portfolioItemId}`,
            method: 'PUT',
            data,
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(`Failed to update portfolio: ${error.message}`)
        }
    },

    getAllVersions: async ({
                               companyId,
                               perPage,
                               page,
                           }: {
        companyId: string
        perPage?: number
        page?: number
    }): Promise<{
        dataVersionList: PortfolioDataVersion[]
        pagingInfo: PageInfo
    }> => {
        const config = {
            url: `/organizations/${companyId}/portfolio/list/versions`,
            method: 'GET',
            params: {
                perPage,
                page,
            },
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(
                `Failed to fetch portfolio versions: ${error.message}`,
            )
        }
    },

    getTypes: async ({companyId}: {
        companyId: string
    }): Promise<{
        portfolioTypes: String[]
    }> => {
        const config = {
            url: `/organizations/referencedata/artikeltype`,
            method: 'GET'
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(
                `Failed to fetch portfolio types: ${error.message}`,
            )
        }
    },

    getPortfolioChanges: async ({
                                    companyId,
                                    version,
                                    perPage,
                                    page,
                                }: {
        companyId: string
        version?: string
        perPage?: number
        page?: number
    }): Promise<{
        portfolioChangeList: PortfolioChange[]
        pagingInfo: PageInfo
    }> => {
        const config = {
            url: version ? `/organizations/${companyId}/portfolio/${version}/list/changes` : `/organizations/${companyId}/portfolio/list/changes`,
            method: 'GET',
            params: {
                perPage,
                page,
            },
        }

        try {
            const res = await instance.request(config)

            return res.data
        } catch (error: any) {
            throw new Error(
                `Failed to fetch overview of changes: ${error.message}`,
            )
        }
    },

    deployToTestInstance: async ({companyId}: { companyId: string }) => {
        const config = {
            url: `/organizations/${companyId}/portfolio/deploy/TEST`,
            method: 'POST',
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            let data = error.response.data;
            if (data.message) {
                throw new Error(`Failed to deploy to test instance: ${data.message}`)
            } else {
                throw new Error(`Failed to deploy to test instance: ${data}`)
            }
        }
    },

    deployToProduction: async ({
                                   scheduledTime,
                                   companyId,
                               }: {
        companyId: string
        scheduledTime: string
    }) => {
        const config = {
            url: `/organizations/${companyId}/portfolio/deploy/PRODUCTION`,
            method: 'POST',
            params: {
                scheduledTime,
            },
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(`Failed to deploy to production: ${error.response.data.message}`)
        }
    },

    scheduleDeploymentToProduction: async ({
                                               scheduledTime,
                                               companyId,
                                           }: {
        companyId: string
        scheduledTime: string
    }) => {
        const config = {
            url: `/organizations/${companyId}/portfolio/deploy/PRODUCTION`,
            method: 'POST',
            data: {
                scheduledTime,
            },
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            console.log("Failed to deploy to production", error)
            throw new Error(`Failed to deploy to production: ${error.message}`)
        }
    },

    cancelDeployment: async ({
                                 companyId,
                                 deploymentId,
                             }: {
        companyId: string
        deploymentId: string
    }) => {
        const config = {
            url: `/organizations/${companyId}/portfolio/deployments/PRODUCTION/${deploymentId}`,
            method: 'DELETE',
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(`Failed to cancel production deployment: ${error.message}`)
        }
    },
}

export default PortfolioApi
