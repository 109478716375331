import toast from 'react-hot-toast'
import {t as translate} from 'i18next'
import {useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {Link, useNavigate} from 'react-router-dom'

import {PageInfo} from 'src/services/api/portfolioApi'
import {useAuthContext} from 'src/context/AuthProvider'
import ToastWidget from 'src/components/parts/ToastWidget'
import DeploymentApi from 'src/services/api/deploymentApi'
import {usePortfolioItemQuery} from 'src/services/hooks/usePortfolioItemQuery'
import {usePortfolioChangesQuery} from 'src/services/hooks/usePortfolioOverviewChangesDataQuery'

export const useHooks = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const {company} = useAuthContext()
    const {getTestDeployment} = DeploymentApi
    const {deployPortfolio} = usePortfolioItemQuery({})

    const [isShowCreateInstanceModal, setIsShowCreateInstanceModal] =
        useState(false)
    const [currentPage, setCurrentPage] = useState<number | undefined>(
        undefined,
    )
    const [perPage, setPerPage] = useState<number | undefined>(undefined)
    const [pageInfo, setPageInfo] = useState<PageInfo | undefined>(undefined)

    const {
        portfolioChangesData,
        isLoadingPortfolioChangesData,
        refetchPortfolioChangesData,
    } = usePortfolioChangesQuery({
        companyId: company?.uuid,
        page: currentPage,
        perPage,
    })
    const portfolioChanges = portfolioChangesData?.portfolioChangeList || []

    const deployToTestInstance = async () => {
        if (!company) return;
        
        try {
            document.body.classList.add('loading');
            const deploymentId = await deployPortfolio({
                companyId: company.uuid,
            })

            setIsShowCreateInstanceModal(true)
            fetchNewDeployment({
                companyId: company.uuid,
                deploymentId,
            })
        } catch (err: any) {
            toast.error(
                <ToastWidget
                    label={translate('creating-test-instance', {
                        ns: 'portfolio',
                    })}
                    message={err.message}
                />,
                {position: 'bottom-right'},
            )
        }
    }

    const fetchNewDeployment = async ({
                                          companyId,
                                          deploymentId,
                                      }: {
        companyId: string
        deploymentId: string
    }) => {
        try {
            const deployment = await getTestDeployment({
                companyId,
                deploymentId,
            })

            if (deployment.deploymentStatus === 'Success') {
                setIsShowCreateInstanceModal(false)
                toast.dismiss()

                toast.success(
                    (t) => (
                        <ToastWidget
                            label={translate('creating-test-instance', {
                                ns: 'portfolio',
                            })}
                            message={
                                <div className='flex flex-col'>
                                    <span>
                                        {translate('success', {
                                            ns: 'deployment',
                                        })}
                                    </span>
                                    <Link
                                        onClick={() => toast.dismiss(t.id)}
                                        to='/deployment/test-instance'
                                        className='underline text-info'
                                    >
                                        {translate('view-details', {
                                            ns: 'portfolio',
                                        })}
                                    </Link>
                                </div>
                            }
                            handleClose={() => toast.dismiss(t.id)}
                        />
                    ),
                    {position: 'bottom-right', duration: Infinity},
                )

                queryClient.invalidateQueries('deployments')
            } else if (deployment.deploymentStatus === 'Failure') {
                setIsShowCreateInstanceModal(false)
                toast.dismiss()

                toast.error(
                    (t) => (
                        <ToastWidget
                            label={translate('creating-test-instance', {
                                ns: 'portfolio',
                            })}
                            message={
                                <div className='flex flex-col'>
                                    <span>
                                        {translate('failed', {
                                            ns: 'deployment',
                                        })}
                                    </span>
                                    <Link
                                        onClick={() => toast.dismiss(t.id)}
                                        to='/deployment/test-instance'
                                        className='underline text-info'
                                    >
                                        {translate('view-details', {
                                            ns: 'portfolio',
                                        })}
                                    </Link>
                                </div>
                            }
                            handleClose={() => toast.dismiss(t.id)}
                        />
                    ),
                    {position: 'bottom-right', duration: Infinity},
                )

                queryClient.invalidateQueries('deployments')
            } else {
                setTimeout(
                    () => fetchNewDeployment({companyId, deploymentId}),
                    10 * 1000,
                )
            }
        } catch (error) {
            setTimeout(
                () => fetchNewDeployment({companyId, deploymentId}),
                2 * 1000,
            )
        }
    }

    useEffect(() => {
        if (!portfolioChangesData?.pagingInfo) return

        const {currentPage, pageCount, perPage, totalCount} =
            portfolioChangesData?.pagingInfo

        setPageInfo({
            currentPage,
            perPage,
            totalCount,
            pageCount,
        })
    }, [portfolioChangesData])

    useEffect(() => {
        refetchPortfolioChangesData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perPage, currentPage])

    const handleResetPage = (perPage: number) => {
        setCurrentPage(1)
        setPerPage(perPage)
    }

    const minimizeAndNotify = async () => {
        toast.loading(
            (t) => (
                <ToastWidget
                    label={translate('creating-test-instance', {
                        ns: 'portfolio',
                    })}
                    message={translate('in-progress', {ns: 'portfolio'})}
                    handleClose={() => toast.dismiss(t.id)}
                />
            ),
            {position: 'bottom-right'},
        )

        setIsShowCreateInstanceModal(false)
        navigate('/deployment/test-instance')
    }

    return {
        pageInfo,
        portfolioChanges,
        portfolioChangesData,
        isShowCreateInstanceModal,
        isLoadingPortfolioChangesData,
        setCurrentPage,
        handleResetPage,
        minimizeAndNotify,
        deployToTestInstance,
    }
}
