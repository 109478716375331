import { t } from 'i18next'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import Pill from '../Pill'
import Dropdown from '../Dropdown'

type StatusIndicatorProps = {
    isActive: boolean
    isSupplier: boolean
    isRepresentant?: boolean
    onStatusChange?: (value: boolean) => void
    className?: string
    userRole?: string
}

const StatusIndicator = ({
    isActive,
    isSupplier,
    isRepresentant,
    onStatusChange,
    className = ''
}: StatusIndicatorProps) => {
    const statusOptions = [
        { id: 1, label: 'ACTIVE', value: true },
        { id: 2, label: 'INACTIVE', value: false }
    ]

    // If item is inactive and trying to set as representant, force status to be read-only
    if (!isActive && isRepresentant) {
        return (
            <Pill
                label={t('inactive', {ns: 'portfolio'})}
                className={`px-8 h-6 !w-fit !rounded-full font-semibold text-xs text-white bg-primary-red/80 ${className}`}
            />
        )
    }

    // Suppliers can't change status
    if (isSupplier) {
        return (
            <Pill
                label={isActive ? t('active', {ns: 'portfolio'}) : t('inactive', {ns: 'portfolio'})}
                className={`px-8 h-6 !w-fit !rounded-full font-semibold text-xs text-white ${isActive ? 'bg-primary-green/80' : 'bg-primary-red/80'} ${className}`}
            />
        )
    }

    // Can't deactivate representative items
    if (isRepresentant && isActive) {
        return (
            <Pill
                label={isActive ? t('active', {ns: 'portfolio'}) : t('inactive', {ns: 'portfolio'})}
                className={`px-8 h-6 !w-fit !rounded-full font-semibold text-xs text-white ${isActive ? 'bg-primary-green/80' : 'bg-primary-red/80'} ${className}`}
            />
        )
    }

    return (
        <Dropdown
            options={isRepresentant ? statusOptions.filter(option => option.value === isActive) : statusOptions}
            className={`px-8 h-6 !w-fit !rounded-full font-semibold text-xs text-white ${isActive ? 'bg-primary-green/80' : 'bg-primary-red/80'} ${className}`}
            children={
                <>
                    <span className="uppercase">
                        {isActive ? t('active', {ns: 'portfolio'}) : t('inactive', {ns: 'portfolio'})}
                    </span>
                    <ChevronDownIcon className='h-4 w-4 text-white'/>
                </>
            }
            handleSelectedOption={onStatusChange}
            hoverText={isRepresentant ? t('cannot-change-status', {ns: 'portfolio'}) : ""}
        />
    )
}

export default StatusIndicator
