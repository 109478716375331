import {t} from 'i18next'

import Pill from 'src/components/parts/Pill'
import Button from 'src/components/parts/Button'
import BackButton from 'src/components/parts/BackButton'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import PortfolioChangesTable from 'src/components/parts/PortfolioChangesTable'

import {useHooks} from './hooks'

const PortfolioTestDeployment = () => {
    const {
        pageInfo,
        portfolioChanges,
        portfolioChangesData,
        isShowCreateInstanceModal,
        isLoadingPortfolioChangesData,
        setCurrentPage,
        handleResetPage,
        minimizeAndNotify,
        deployToTestInstance,
    } = useHooks()

    const renderPortfolioChangesTable = () => (
        <PortfolioChangesTable
            portfolioChanges={portfolioChanges}
            pageInfo={pageInfo}
            handleResetPage={handleResetPage}
            setCurrentPage={setCurrentPage}
        />
    )

    if (isLoadingPortfolioChangesData) {
        return <LoadingSpinner/>
    }

    return (
        <div className='flex flex-col w-full gap-4 text-primary-dark'>
            <div className='flex items-center gap-4'>
                <BackButton/>
                <span className='font-bold text-2xl'>
                    {t('test', {ns: 'portfolio'})}
                </span>
            </div>
            <div className='flex gap-2 text-sm font-semibold text-primary-dark'>
                <span> {t('overview-of-changes', {ns: 'portfolio'})}</span>
                <Pill
                    className='min-w-7 text-primary-dark bg-neutral-400'
                    label={portfolioChangesData?.pagingInfo.totalCount}
                />
            </div>
            {renderPortfolioChangesTable()}
            <div className='fixed bottom-0 right-0 flex justify-end w-full gap-2 p-6 bg-white'>
                <Button
                    onClick={deployToTestInstance}
                    variant='contained'
                    color='primary-dark'
                    label={t('create-test-instance', {ns: 'portfolio'})}
                />
            </div>
            {isShowCreateInstanceModal && (
                <div className='fixed inset-0 flex items-center justify-center h-screen w-full bg-primary-dark/50'>
                    <div className='absolute flex flex-col items-center max-w-96 gap-6 p-5 rounded-lg bg-white z-50'>
                        <span className='text-base font-bold '>
                            {t('creating-test-instance', {ns: 'portfolio'})}
                        </span>
                        <LoadingSpinner classname='!h-10 !w-10'/>
                        <span className='text-center'>
                            {t('process-notification', {ns: 'portfolio'})}
                        </span>
                        <div className='flex w-fit'>
                            <Button
                                onClick={minimizeAndNotify}
                                variant='contained'
                                color='primary-dark'
                                label={t('minimize-and-notify', {
                                    ns: 'portfolio',
                                })}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PortfolioTestDeployment
