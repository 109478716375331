import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { PageInfo, Portfolio } from 'src/services/api/portfolioApi'
import Pill from 'src/components/parts/Pill'
import Paginator from 'src/components/templates/Paginator'
import NoDetailsToShow from 'src/components/parts/NoDetailsToShow'

type PortfolioChangesTableProps = {
    portfolioChanges: Array<{
        uuid: string
        Artikelnummer: string
        Name: string
        status?: string
        updatedFields: string[]
        activeChange?: {
            field: string
            oldValue: boolean
            newValue: boolean
        }
        repraesentantChange?: {
            field: string
            oldValue: boolean
            newValue: boolean
        }
    }>
    version: string
    version?: string
    pageInfo?: PageInfo
    handleResetPage: (perPage: number) => void
    setCurrentPage: (page: number) => void
}

const PortfolioChangesTable = ({
    portfolioChanges,
    pageInfo,
    handleResetPage,
    setCurrentPage,
    version
}: PortfolioChangesTableProps) => {
    return (
        <div className='flex flex-col'>
            <div className='table-container max-h-[calc(100vh-360px)] overflow-y-auto'>
                <table className='w-full text-sm text-left bg-neutral-50'>
                    <thead className='sticky top-0 text-sm uppercase bg-neutral-200'>
                        <tr>
                            <th
                                scope='col'
                                className='w-40 px-8 py-5 text-gray-700 whitespace-nowrap'
                            >
                                {t('article-number', {ns: 'portfolio'})}
                            </th>
                            <th
                                scope='col'
                                className='w-[480px] px-8 py-5 text-gray-700 whitespace-nowrap '
                            >
                                {t('name', {ns: 'portfolio'})}
                            </th>
                            <th
                                scope='col'
                                className='px-8 py-5 text-gray-700 whitespace-nowrap'
                            >
                                {t('changes', {
                                    ns: 'reference',
                                })}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {portfolioChanges.length > 0 ?
                            portfolioChanges.map(
                                (
                                    {
                                        uuid,
                                        Artikelnummer,
                                        Name,
                                        status,
                                        updatedFields,
                                        activeChange,
                                        repraesentantChange,
                                    },
                                    index,
                                ) => {
                                    return (
                                        <tr
                                            key={index}
                                            className='group border-neutral-200 border-b-[1px] hover:bg-white'
                                        >
                                            <td className='w-40 px-8 py-4 whitespace-nowrap'>
                                                <Link
                                                    to={`/portfolio/item/${uuid}?version=${version}`}
                                                    className="text-primary-dark hover:underline"
                                                >
                                                    {Artikelnummer}
                                                </Link>
                                            </td>
                                            <td className='flex min-96 w-max-[480px] gap-2 px-8 py-4'>
                                                    <Link
                                                        to={`/portfolio/item/${uuid}?version=${version}`}
                                                        className='whitespace-nowrap overflow-hidden overflow-ellipsis text-primary-dark hover:underline'
                                                    >
                                                        {Name}
                                                    </Link>
                                                {status && (
                                                    <Pill
                                                        label={status}
                                                        className='bg-primary-blue/50 text-white'
                                                    />
                                                )}
                                            </td>
                                            <td className='px-8 py-4 whitespace-nowrap overflow-hidden overflow-ellipsis'>
                                                {activeChange?.newValue &&
                                                    activeChange?.newValue !==
                                                    activeChange?.oldValue && (
                                                        <span className='flex gap-1'>
                                                                {t('updated', {
                                                                    ns: 'portfolio',
                                                                })}{' '}
                                                            <span className='font-semibold uppercase'>
                                                                    {t(
                                                                        'active',
                                                                        {
                                                                            ns: 'portfolio',
                                                                        },
                                                                    )}
                                                                </span>{' '}
                                                            {t(
                                                                'value-from',
                                                                {
                                                                    ns: 'portfolio',
                                                                },
                                                            )}
                                                            <Pill
                                                                label={`${activeChange.oldValue}`}
                                                                className='bg-gray-600/50 text-white w-fit'
                                                            />
                                                                to
                                                                <Pill
                                                                    label={`${activeChange.newValue}`}
                                                                    className='bg-primary-green/50 text-white w-fit'
                                                                />
                                                            </span>
                                                    )}

                                                {repraesentantChange?.newValue &&
                                                    repraesentantChange?.newValue !==
                                                    repraesentantChange?.oldValue && (
                                                        <span className='flex gap-1 my-1'>
                                                                {t('updated', {
                                                                    ns: 'portfolio',
                                                                })}
                                                            <span className='font-semibold uppercase'>
                                                                    {t(
                                                                        'representant',
                                                                        {
                                                                            ns: 'portfolio',
                                                                        },
                                                                    )}
                                                                </span>{' '}
                                                            {t(
                                                                'value-from',
                                                                {
                                                                    ns: 'portfolio',
                                                                },
                                                            )}
                                                            <Pill
                                                                label={`${repraesentantChange.oldValue}`}
                                                                className='bg-gray-600/50 text-white w-fit'
                                                            />
                                                                to
                                                                <Pill
                                                                    label={`${repraesentantChange.newValue}`}
                                                                    className='bg-primary-green/50 text-white w-fit'
                                                                />
                                                            </span>
                                                    )}

                                                {updatedFields.map(
                                                    (field, index) => (
                                                        <span
                                                            key={index}
                                                            className='flex gap-1'
                                                        >
                                                                {t('updated', {
                                                                    ns: 'portfolio',
                                                                })}
                                                                 <span className='font-semibold pr-4'>
                                                                    {field}
                                                                </span>
                                                            </span>
                                                    ),
                                                )}
                                            </td>
                                        </tr>
                                    )
                                },
                            )
                            : <tr>
                                <td colSpan={3}>
                                    <NoDetailsToShow/>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            {pageInfo && (
                <Paginator
                    pageInfo={pageInfo}
                    handleSetPerPage={handleResetPage}
                    handleSetCurrentPage={setCurrentPage}
                />
            )}
        </div>
    )
}

export default PortfolioChangesTable
