import {t} from 'i18next'
import {Link} from 'react-router-dom'

import Pill from 'src/components/parts/Pill'
import Modal from 'src/components/parts/Modal'
import Switch from 'src/components/parts/Switch'
import Button from 'src/components/parts/Button'
import StatusIndicator from 'src/components/parts/StatusIndicator'
import Paginator from 'src/components/templates/Paginator'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import NoDetailsToShow from 'src/components/parts/NoDetailsToShow'
import ScrollableSliderTabs from 'src/components/templates/SliderTabs'
import {getPillChangeStatusColor} from 'src/utils/getPillStatusColor'
import LoadingCard from 'src/components/parts/LoadingCard'

import {useHooks} from './hooks'
import React, {useState} from "react";
import {useAuthContext} from "../../context/AuthProvider.tsx";

const PortfolioTablePage = () => {
    const {
        pageInfo,
        company,
        filters,
        activeFilter,
        statusOptions,
        filteredPortfolioItems,
        isLoadingPortfolioData,
        isShowPublishConfirmationModal,
        setCurrentPage,
        getCount,
        rollback,
        navigate,
        handleResetPage,
        handleSetStatus,
        handleSelectTab,
        publishReferenceData,
        handleSetRepresentant,
        setIsShowPublishConfirmationModal,
        isSupplierCompany,
        sortField = 'name',
        sortOrder = 'asc',
        setSortField,
        setSortOrder
    } = useHooks()

    const [isPublishing, setIsPublishing] = useState(false)
    const [isShowPublishSpinnerModal, setIsShowPublishSpinnerModal] = useState(false)

    if (isLoadingPortfolioData) {
        return <LoadingSpinner/>
    }

    const PortfolioItemTable = () => {
        const {auth, isAdmin} = useAuthContext()
        return (
            <div className='flex flex-col'>
                <div className='table-container max-h-[calc(100vh-280px)] overflow-y-auto'>
                    <table className='w-full text-sm text-left bg-neutral-50'>
                        <thead className='sticky top-0 text-sm uppercase bg-neutral-200'>
                        <tr>
                            <th
                                scope='col'
                                className='w-60 max-w-60 px-4 py-3 text-gray-700 whitespace-nowrap cursor-pointer'
                                onClick={() => {
                                    if (sortField === 'artikelNummer') {
                                        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                                    } else {
                                        setSortField('artikelNummer')
                                        setSortOrder('asc')
                                    }
                                }}
                            >
                                <div className="flex items-center gap-2">
                                    {t('article-number', {ns: 'portfolio'})}
                                    {sortField === 'artikelNummer' && (
                                        <span className="text-xs">
                                            {sortOrder === 'asc' ? '↑' : '↓'}
                                        </span>
                                    )}
                                </div>
                            </th>
                            <th
                                scope='col'
                                className='flex-1 px-4 py-3 text-gray-700 whitespace-nowrap cursor-pointer'
                                onClick={() => {
                                    if (sortField === 'name') {
                                        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                                    } else {
                                        setSortField('name')
                                        setSortOrder('asc')
                                    }
                                }}
                            >
                                <div className="flex items-center gap-2">
                                    {t('name', {ns: 'portfolio'})}
                                    <span className="text-xs">
                                        {sortOrder === 'asc' ? '↑' : '↓'}
                                    </span>
                                </div>
                            </th>
                            <th
                                scope='col'
                                className='w-32 max-w-40 px-4 py-3 text-gray-700 whitespace-nowrap text-right'
                            >
                                {t('representative', {
                                    ns: 'portfolio',
                                })}
                            </th>
                            <th
                                scope='col'
                                className='w-32 px-4 py-3 text-gray-700 whitespace-nowrap text-right'
                            >
                                {t('status', {
                                    ns: 'portfolio',
                                })}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredPortfolioItems.length > 0 ?
                            filteredPortfolioItems.map((portfolioItem) => {
                                const {
                                    uuid,
                                    active,
                                    artikelNummer,
                                    referenceData,
                                    Repraesentant,
                                    changeStatus,
                                } = portfolioItem

                                return (
                                    <tr
                                        key={uuid}
                                        className='group border-neutral-200 border-b-[1px] hover:bg-neutral-100'
                                    >
                                        <td className='w-32 max-w-60 px-4 py-2 whitespace-nowrap'>
                                            {artikelNummer}
                                        </td>
                                        <td className='flex flex-1 gap-2 px-4 py-2'>
                                            <Link to={`item/${uuid}`}>
                                                {referenceData.Name}
                                            </Link>
                                            {changeStatus !==
                                                'UNCHANGED' && (
                                                    <Pill
                                                        label={changeStatus}
                                                        className={`text-white ${getPillChangeStatusColor(changeStatus)}`}
                                                    />
                                                )}
                                        </td>
                                        <td className='w-32 max-w-40 px-4 py-2 whitespace-nowrap text-right'>
                                            <Switch
                                                isSelected={Repraesentant}
                                                handleSelect={() => {
                                                    if (!active) {
                                                        return; // Prevent setting inactive items as representative
                                                    }
                                                    handleSetRepresentant({
                                                        portfolioItem,
                                                        portfolioItemId:
                                                        uuid,
                                                    })
                                                }}
                                                disabled={
                                                    !active || // Disable if item is inactive
                                                    company?.type === 'SUPPLIER' ||
                                                    (company?.type === 'DISTRIBUTOR' &&
                                                        auth?.user?.role?.userRole === 'ORGANIZATION_PRODUCT_MANAGER')
                                                }
                                            />
                                        </td>
                                        <td className='w-32 font-semibold text-xs px-4 py-2 whitespace-nowrap text-right'>
                                            <StatusIndicator
                                                isActive={active}
                                                isSupplier={company?.type === 'SUPPLIER'}
                                                isRepresentant={Repraesentant}
                                                userRole={auth?.user?.role?.userRole}
                                                onStatusChange={(value) => {
                                                    // Prevent deactivating representative items
                                                    if (Repraesentant && !value) {
                                                        return;
                                                    }
                                                    handleSetStatus({
                                                        value,
                                                        portfolioItemId: uuid,
                                                        isRepresentant: Repraesentant,
                                                    })
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td colSpan={5}>
                                    <NoDetailsToShow/>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {pageInfo && (
                    <Paginator
                        pageInfo={pageInfo}
                        handleSetPerPage={handleResetPage}
                        handleSetCurrentPage={setCurrentPage}
                    />
                )}
            </div>
        )
    }

    const handlePublishReferenceData = async () => {
        setIsPublishing(true)
        setIsShowPublishSpinnerModal(true)

        try {
            await publishReferenceData()
        } finally {
            setIsPublishing(false)
            setIsShowPublishSpinnerModal(false)
        }
    }

    return (
        <>
            <div className='flex flex-col w-full gap-4 text-primary-dark'>
                <ScrollableSliderTabs>
                    <ul className='flex gap-6'>
                        {filters.length > 1 &&
                            filters.map(({id, label, type}) => {
                                return (
                                    <li
                                        key={id}
                                        className={`flex gap-2 h-9 text-sm font-bold text-neutral-700 cursor-pointer  ${activeFilter === type && 'text-primary-dark border-b-2 border-primary-dark'} ${getCount(type) ?? 'hidden'}`}
                                        onClick={() => handleSelectTab(type)}
                                    >
                                        {label}
                                        <Pill
                                            className={`min-w-7 text-white ${activeFilter === type ? 'bg-primary-dark' : 'bg-neutral-700'}  ${getCount(type) == -1 ? 'hidden' : ""}`}
                                            label={getCount(type)}
                                        />
                                    </li>
                                )
                            })}
                    </ul>
                </ScrollableSliderTabs>
                <PortfolioItemTable/>
                <div className='action-buttons flex justify-end space-x-2'>
                    <Button
                        onClick={() => navigate('/deployment/versions/LATEST')}
                        variant='contained'
                        label={t('show-changes', {ns: 'portfolio'})}
                    />
                    {company?.type === 'SUPPLIER' && (
                        <Button
                            onClick={() => navigate('import')}
                            variant='contained'
                            label={t('import', {ns: 'portfolio'})}
                        />
                    )}
                    <Button
                        onClick={() => navigate('deploy/test')}
                        variant='contained'
                        label={t('test-portfolio', {ns: 'portfolio'})}
                    />
                    {company?.type === 'DISTRIBUTOR' ?
                        <Button
                            variant='contained'
                            onClick={() => navigate('deploy/prod')}
                            label={t('deploy', {ns: 'portfolio'})}
                        />
                        : <Button
                            variant='contained'
                            onClick={() =>
                                setIsShowPublishConfirmationModal(true)
                            }
                            label={t('publish', {ns: 'portfolio'})}
                        />
                    }
                </div>
            </div>

            {isShowPublishConfirmationModal && (
                <Modal
                    label={t('publish-reference-data', {
                        ns: 'portfolio',
                    })}
                    content={
                        <span>
                            {t('confirm-publish-reference-data', {
                                ns: 'portfolio',
                            })}
                        </span>
                    }
                    handleCancel={() =>
                        setIsShowPublishConfirmationModal(false)
                    }
                    handleConfirm={handlePublishReferenceData}
                    confirmMessage={t('publish', {ns: 'portfolio'})}
                    cancelMessage={t('back', {ns: 'portfolio'})}
                />
            )}

            {isShowPublishSpinnerModal && (
                <LoadingCard
                    label={t('publishing', {ns: 'portfolio'})}
                    children={
                        <span className='text-center'>
                            {t('please-wait-while-the-reference-data-is-being-published', {ns: 'portfolio'})}
                        </span>
                    }
                />
            )}
        </>
    )
}
export default PortfolioTablePage
