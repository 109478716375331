import {t} from 'i18next'

import Pill from 'src/components/parts/Pill'
import Modal from 'src/components/parts/Modal'
import Button from 'src/components/parts/Button'
import BackButton from 'src/components/parts/BackButton'
import InputRadio from 'src/components/parts/InputRadio'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import PortfolioChangesTable from 'src/components/parts/PortfolioChangesTable'

import {useHooks} from './hooks'

const PortfolioProdDeployment = () => {
    const {
        pageInfo,
        dateInputName,
        timeInputName,
        deploymentType,
        portfolioChanges,
        portfolioChangesData,
        isDeploying,
        isShowScheduledDeploymentModal,
        isShowImmediateDeploymentModal,
        isShowDeploymentSelectionModal,
        deploy,
        navigate,
        setCurrentPage,
        handleResetPage,
        deploymentSubmit,
        minimizeAndNotify,
        deploymentRegister,
        setIsShowScheduledDeploymentModal,
        setIsShowDeploymentSelectionModal,
    } = useHooks()

    const renderPortfolioChangesTable = () => (
        <PortfolioChangesTable
            portfolioChanges={portfolioChanges}
            pageInfo={pageInfo}
            handleResetPage={handleResetPage}
            setCurrentPage={setCurrentPage}
        />
    )

    return (
        <>
            <div className='flex flex-col w-full gap-4 text-primary-dark'>
                <div className='flex items-center gap-4'>
                    <BackButton/>
                    <span className='font-bold text-2xl'>{t('deploy', {ns: 'portfolio'})}</span>
                </div>
                <div className='flex gap-2 text-sm font-semibold text-primary-dark'>
                    <span>{t('overview-of-changes', {ns: 'portfolio'})}</span>
                    <Pill
                        className='min-w-7 text-primary-dark bg-neutral-400'
                        label={portfolioChangesData?.pagingInfo.totalCount}
                    />
                </div>
                {renderPortfolioChangesTable()}
                <div className='fixed bottom-0 right-0 flex justify-end w-full gap-2 p-6 bg-white'>
                    <Button
                        onClick={() => setIsShowDeploymentSelectionModal(true)}
                        variant='contained'
                        color='primary-dark'
                        label={t('deploy', {ns: 'portfolio'})}
                    />
                </div>
            </div>

            {isShowDeploymentSelectionModal && (
                <Modal
                    label={t('deploy', {ns: 'portfolio'})}
                    content={
                        <div className='flex flex-col w-96 gap-8'>
                            <div className='flex flex-col gap-2 text-primary-dark'>
                                <span>{t('deployNowOrLater', {ns: 'portfolio'})}</span>
                                {[
                                    {
                                        id: 1,
                                        label: t('deployNow', {ns: 'portfolio'}),
                                        value: 'deploy-now',
                                    },
                                    {
                                        id: 2,
                                        label: t('deployLater', {ns: 'portfolio'}),
                                        value: 'deploy-later',
                                    },
                                ].map(({id, label, value}) => {
                                    return (
                                        <InputRadio
                                            key={id}
                                            id={label}
                                            register={deploymentRegister}
                                            name='deploymentType'
                                            label={label}
                                            value={value}
                                            className='text-sm'
                                        />
                                    )
                                })}
                                {deploymentType === 'deploy-later' && (
                                    <>
                                        <input
                                            type='date'
                                            {...deploymentRegister(
                                                'dateInputName',
                                            )}
                                            name='dateInputName'
                                            className='h-10 w-full py-[14px] px-3 border border-1 border-neutral-300 rounded-[4px]'
                                            min={
                                                new Date()
                                                    .toISOString()
                                                    .split('T')[0]
                                            }
                                        />
                                        <input
                                            type='time'
                                            {...deploymentRegister(
                                                'timeInputName',
                                            )}
                                            name='timeInputName'
                                            className='h-10 w-full py-[14px] px-3 border border-1 border-neutral-300 rounded-[4px]'
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    }
                    handleCancel={() =>
                        setIsShowDeploymentSelectionModal(false)
                    }
                    handleConfirm={deploymentSubmit(deploy)}
                    confirmMessage={
                        isDeploying ? (
                            <div className="flex items-center gap-2">
                                <LoadingSpinner classname="!h-4 !w-4" />
                                <span>Deploying...</span>
                            </div>
                        ) : (
                            'Deploy'
                        )
                    }
                    cancelMessage='Back'
                    disabled={isDeploying}
                />
            )}

            {isShowImmediateDeploymentModal && (
                <div className='fixed inset-0 flex items-center justify-center h-screen w-full bg-primary-dark/50 z-50'>
                    <div className='absolute flex flex-col items-center max-w-96 gap-6 p-5 rounded-lg bg-white'>
                        <span className='text-base font-bold '>
                            Deploying to Production {/* TODO */}
                        </span>
                        <LoadingSpinner classname='!h-10 !w-10'/>
                        <span className='text-center'>
                            This will only take a moment. We will notify you as
                            soon as possible.
                        </span>
                        <div className='flex w-fit'>
                            <Button
                                onClick={minimizeAndNotify}
                                variant='contained'
                                color='primary-dark'
                                label='Minimize and Notify Me'
                            />
                        </div>
                    </div>
                </div>
            )}

            {isShowScheduledDeploymentModal && (
                <div className='fixed inset-0 flex items-center justify-center h-screen w-full bg-primary-dark/50 z-50'>
                    <div className='absolute flex flex-col max-w-96 gap-6 p-5 rounded-lg bg-white'>
                        <span className='text-base font-bold '>
                            Deployment Schedule
                        </span>
                        <div>
                            We are scheduling the deployment on{' '}
                            <span className='font-bold'>{dateInputName}</span>{' '}
                            at
                            <span className='font-bold'> {timeInputName}</span>
                        </div>
                        <div className='flex justify-end w-full'>
                            <Button
                                onClick={() => {
                                    setIsShowScheduledDeploymentModal(false)
                                    navigate('/deployment/production')
                                }}
                                variant='contained'
                                color='primary-dark'
                                label='OK'
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default PortfolioProdDeployment
