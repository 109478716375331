import {useEffect, useState} from 'react'
import {useAuthContext} from 'src/context/AuthProvider'
import {PageInfo} from 'src/services/api/portfolioApi'
import {usePortfolioChangesQuery} from 'src/services/hooks/usePortfolioOverviewChangesDataQuery'

export const useVersionPortfolioChanges = (version?: string) => {
    const {company} = useAuthContext()
    const companyId = company?.uuid

    const [currentPage, setCurrentPage] = useState<number | undefined>(undefined)
    const [perPage, setPerPage] = useState<number | undefined>(undefined)
    const [pageInfo, setPageInfo] = useState<PageInfo | undefined>(undefined)

    const {
        portfolioChangesData,
        isLoadingPortfolioChangesData: isLoadingPortfolioChanges,
        refetchPortfolioChangesData
    } = usePortfolioChangesQuery({
        companyId,
        version,
        page: currentPage,
        perPage
    })

    const portfolioChanges = portfolioChangesData?.portfolioChangeList || []

    useEffect(() => {
        if (!portfolioChangesData?.pagingInfo) return

        const {currentPage, pageCount, perPage, totalCount} =
            portfolioChangesData?.pagingInfo

        setPageInfo({
            currentPage,
            perPage,
            totalCount,
            pageCount,
        })
    }, [portfolioChangesData])

    useEffect(() => {
        refetchPortfolioChangesData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perPage, currentPage])

    const handleResetPage = (perPage: number) => {
        setCurrentPage(1)
        setPerPage(perPage)
    }

    return {
        pageInfo,
        portfolioChanges,
        portfolioChangesData,
        isLoadingPortfolioChanges,
        setCurrentPage,
        handleResetPage,
    }
}
