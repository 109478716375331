import toast from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import PortfolioApi from 'src/services/api/portfolioApi'

type PortfolioItemQueryProps = {
    companyId?: string
    portfolioItemId?: string
    version?: string | null
}

export const usePortfolioItemQuery = ({
    companyId,
    portfolioItemId,
    version,
}: PortfolioItemQueryProps) => {
    const { get, getVersion, update, deployToTestInstance } = PortfolioApi

    const queryClient = useQueryClient()

    const { data: portfolioItem, isLoading: isLoadingPortfolioItem } = useQuery(
        {
            queryKey: 'portfolioItem',
            queryFn: () =>
                companyId && portfolioItemId ?
                    version ?
                        getVersion({ companyId, portfolioItemId, version })
                    :   get({ companyId, portfolioItemId })
                :   undefined,
            onError: (error: any) => {
                toast.error(error.message)
            },
            retry: 2,
        },
    )

    const {
        mutateAsync: updatePortfolioItem,
        isLoading: isLoadingUpdatePortfolioItem,
    } = useMutation(update, {
        onSuccess: () => {
            queryClient.invalidateQueries('portfolioItem')
            queryClient.invalidateQueries('portfolioData')
            queryClient.invalidateQueries('portfolioChangesData')
            toast.success('Update succesful!')
        },
    })

    const {
        mutateAsync: deployPortfolio,
        isLoading: isLoadingDeployPortfolio,
        isError: isDeployPortfolioError,
    } = useMutation(deployToTestInstance, {
        onSuccess: () => {
            queryClient.invalidateQueries('deployments')
        },
    })

    return {
        portfolioItem,
        isLoadingPortfolioItem,
        isLoadingDeployPortfolio,
        isLoadingUpdatePortfolioItem,
        isDeployPortfolioError,
        updatePortfolioItem,
        deployPortfolio,
    }
}
