import instance from './axiosInstance'

export type Deployment = {
    url: string
    deploymentUuid: string
    deploymentDate: number
    deploymentEnvironment: string
    deploymentStatus: 'Success' | 'Pending' | 'Failed' | 'Scheduled' | 'Canceled' | 'Failure'
    scheduledTime: string | null
    message: string
    organizationName: string
    organizationUuid: string
    portfolioVersion: string
    requestedBy: string
}

const DeploymentApi = {
    getTestDeployment: async ({
        companyId,
        deploymentId,
    }: {
        companyId: string
        deploymentId: string
    }): Promise<Deployment> => {
        const config = {
            url: `/organizations/${companyId}/portfolio/deployments/TEST/${deploymentId}`,
            method: 'GET',
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(`Failed to get deployment: ${error.message}`)
        }
    },

    getProdDeployment: async ({
        companyId,
        deploymentId,
    }: {
        companyId: string
        deploymentId: string
    }): Promise<Deployment> => {
        const config = {
            url: `/organizations/${companyId}/portfolio/deployments/PRODUCTION/${deploymentId}`,
            method: 'GET',
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(`Failed to get deployment: ${error.message}`)
        }
    },

    getAllTestDeployments: async ({
        companyId,
    }: {
        companyId: string
    }): Promise<Deployment[]> => {
        const config = {
            url: `/organizations/${companyId}/portfolio/deployments/TEST`,
            method: 'GET',
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(`Failed to get all deployments: ${error.message}`)
        }
    },

    getAllProdDeployments: async ({
        companyId,
        page,
        perPage
    }: {
        companyId: string
        page?: number
        perPage?: number
    }): Promise<{data: Deployment[], pagingInfo: any}> => {
        const config = {
            url: `/organizations/${companyId}/portfolio/deployments/PRODUCTION`,
            method: 'GET',
            params: {
                page,
                perPage
            }
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(`Failed to get all deployments: ${error.message}`)
        }
    },
}

export default DeploymentApi
