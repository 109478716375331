import {t} from 'i18next'
import {PhotoIcon} from '@heroicons/react/24/solid'
import {useSearchParams} from 'react-router-dom'

import Pill from 'src/components/parts/Pill'
import {useAuthContext} from 'src/context/AuthProvider'
import Switch from 'src/components/parts/Switch'
import Detail from 'src/components/parts/Detail'
import StatusIndicator from 'src/components/parts/StatusIndicator'
import BackButton from 'src/components/parts/BackButton'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import NoDetailsToShow from 'src/components/parts/NoDetailsToShow'

import {useHooks} from './hooks'
import {getPillChangeStatusColor} from "../../../../utils/getPillStatusColor.ts";
import React from "react";

const statusOptions = [
    {
        id: 1,
        label: 'ACTIVE',
        value: true,
    },
    {
        id: 2,
        label: 'INACTIVE',
        value: false,
    },
]


const PortfolioItemDetail = () => {
    const [searchParams] = useSearchParams()
    const version = searchParams.get('version')
    const {
        portfolioItem,
        isLoadingPortfolioItem,
        handleSetStatus,
        handleSetRepresentant,
    } = useHooks()
    const {company, auth} = useAuthContext()

    if (isLoadingPortfolioItem) {
        return <LoadingSpinner/>
    }
    return (
        <div className='flex flex-col w-full gap-4 text-primary-dark'>
            <div className='flex items-center gap-4'>
                <BackButton href='/portfolio'/>
                <span className='font-bold text-lg'>
                    {t('back', {ns: 'portfolio'})}
                </span>
            </div>
            {portfolioItem ?
                <div className='flex space-x-8'>
                    <div
                        className='flex justify-center items-center w-56 h-56 rounded-lg border-primary-dark bg-neutral-300'>
                        <PhotoIcon className='w-10 h-10 text-neutral-400'/>
                    </div>

                    <div className='flex flex-col w-full gap-4'>
                        <div className='flex space-x-2 items-center'>
                            <div className='flex items-center gap-2'>
                                <span className='font-bold text-2xl'>
                                    {portfolioItem.referenceData.Name}
                                </span>
                                <Pill
                                    label={version || 'LATEST'}
                                    className='text-white bg-primary-dark'
                                />
                            </div>
                            {portfolioItem.changeStatus !==
                                'UNCHANGED' && (
                                    <Pill
                                        label={portfolioItem.changeStatus}
                                        className={`text-white ${getPillChangeStatusColor(portfolioItem.changeStatus)}`}
                                    />
                                )}
                        </div>

                        {/* MAIN DETAILS */}
                        <div
                            className='flex flex-col gap-6 px-6 py-4 bg-neutral-50 border border-neutral-400 rounded-lg'>
                            <span className='font-bold uppercase'>
                                {t('main-details', {ns: 'portfolio'})}
                            </span>
                            <div className='flex flex-wrap text-neutral-900 gap-6'>
                                <Detail
                                    label={t('article-number', {
                                        ns: 'portfolio',
                                    })}
                                    value={portfolioItem.artikelNummer}
                                />
                                <Detail
                                    label={t('article-type', {
                                        ns: 'portfolio',
                                    })}
                                    value={
                                        portfolioItem.referenceData['@class']
                                    }
                                />
                                <Detail
                                    label={t('representative', {
                                        ns: 'portfolio',
                                    })}
                                    value={
                                        <Switch
                                            disabled={
                                                !portfolioItem.active || // Disable if item is inactive
                                                company.type === 'SUPPLIER' ||
                                                (company.type === 'DISTRIBUTOR' &&
                                                    auth?.user?.role?.userRole === 'ORGANIZATION_PRODUCT_MANAGER')
                                            }
                                            isSelected={portfolioItem.Repraesentant}
                                            handleSelect={() => {
                                                if (!portfolioItem.active) {
                                                    return; // Prevent setting inactive items as representative
                                                }
                                                handleSetRepresentant();
                                            }}
                                        />
                                    }
                                />
                                <Detail
                                    label={t('status', {ns: 'portfolio'})}
                                    value={
                                        <StatusIndicator
                                            isActive={portfolioItem.active}
                                            isSupplier={company.type === 'SUPPLIER'}
                                            isRepresentant={portfolioItem.Repraesentant}
                                            userRole={auth?.user?.role?.userRole}
                                            onStatusChange={(value) => {
                                                // Prevent deactivating representative items
                                                if (portfolioItem.Repraesentant && !value) {
                                                    return;
                                                }
                                                // Prevent setting inactive item as representative
                                                if (portfolioItem.Repraesentant && !portfolioItem.active) {
                                                    return;
                                                }
                                                handleSetStatus(value);
                                            }}
                                        />
                                    }
                                />
                                <Detail
                                    label={"EN"}
                                    value={portfolioItem.referenceData.en}
                                />
                            </div>
                        </div>

                        {/* SECONDARY DETAILS */}
                        <div
                            className='flex flex-col gap-6 px-6 py-4 bg-neutral-50 border border-neutral-400 rounded-lg'>
                            <span className='font-bold uppercase'>
                                {t('secondary-details', {ns: 'portfolio'})}
                            </span>
                            <div className='flex flex-wrap text-neutral-900 gap-10'>
                                {Object.entries(
                                    JSON.parse(
                                        portfolioItem.referenceData.extendInfo,
                                    ),
                                ).map(([key, value], index) => {
                                    return (
                                        <Detail
                                            key={index}
                                            label={key}
                                            value={value as string}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                : <NoDetailsToShow/>}
        </div>
    )
}
export default PortfolioItemDetail
