import {Navigate, Route, Routes} from 'react-router-dom'

import Error from 'src/pages/error'
import Login from 'src/pages/auth/login'
import ProfilePage from 'src/pages/profile'
import CompanyList from 'src/pages/company-list'
import DeploymentPage from 'src/pages/deployment'
import UserDetail from 'src/pages/users/[userId]'
import Layout from 'src/components/templates/Layout'
import PortfolioTablePage from 'src/pages/portfolio'
import CreateCompany from 'src/pages/company-list/new'
import Unauthorized from 'src/pages/error/unauthorized'
import {useAuthContext} from 'src/context/AuthProvider'
import ImportReferenceData from 'src/pages/portfolio/new'
import CompanyPage from 'src/pages/company-list/[companyId]'
import PasswordSetupPage from 'src/pages/auth/password-setup'
import ForgotPasswordPage from 'src/pages/auth/forgot-password'
import PortfolioProdDeployment from 'src/pages/portfolio/deploy/prod'
import PortfolioTestDeployment from 'src/pages/portfolio/deploy/test'
import CreateCompanyUser from 'src/pages/company-list/[companyId]/users/new'
import PortfolioItemDetail from 'src/pages/portfolio/item/[portfolioItemId]'
import DeploymentError from 'src/pages/deployment/prod-deployments/deployment-errors'

import RequireAuth from './require-auth'
import PersistLogin from './persist-login'
import VersionDetails from "../pages/deployment/versions/[version]";

const AppRoutes = () => {
    const {auth} = useAuthContext()

    return (
        <Routes>
            {/* Public routes */}
            <Route path='/login' element={<Login/>}/>
            <Route path='/set-password' element={<PasswordSetupPage/>}/>
            <Route path='/forgot-password' element={<ForgotPasswordPage/>}/>

            {/* Protected routes */}
            <Route element={<PersistLogin/>}>
                {auth?.user && (
                    <Route
                        path='/'
                        element={
                            <Navigate
                                to={
                                    auth?.user.role.userRole === 'ADMIN' ?
                                        '/company'
                                        : '/portfolio'
                                }
                            />
                        }
                    />
                )}

                <Route
                    element={
                        <RequireAuth
                            allowedRoles={['ADMIN', 'ORGANIZATION_ADMIN']}
                        />
                    }
                >
                    <Route path='/' element={<Layout/>}>
                        <Route path='company' element={<CompanyList/>}/>
                        <Route path='company/new' element={<CreateCompany/>}/>
                        <Route
                            path='company/:companyId/:page'
                            element={<CompanyPage/>}
                        />
                        <Route
                            path='company/:companyId/users/new'
                            element={<CreateCompanyUser/>}
                        />
                        <Route path='users/:userId' element={<UserDetail/>}/>
                    </Route>
                </Route>

                <Route
                    element={
                        <RequireAuth
                            allowedRoles={[
                                'ORGANIZATION_ADMIN',
                                'ORGANIZATION_PRODUCT_MANAGER',
                            ]}
                        />
                    }
                >
                    <Route path='/' element={<Layout/>}>
                        <Route path='profile/:page' element={<ProfilePage/>}/>
                        <Route
                            path='portfolio'
                            element={<PortfolioTablePage/>}
                        />
                        <Route
                            path='portfolio/import'
                            element={<ImportReferenceData/>}
                        />
                        <Route
                            path='portfolio/item/:portfolioItemId'
                            element={<PortfolioItemDetail/>}
                        />
                        <Route
                            path='portfolio/deploy/test'
                            element={<PortfolioTestDeployment/>}
                        />
                        <Route
                            path='portfolio/deploy/prod'
                            element={<PortfolioProdDeployment/>}
                        />
                        <Route
                            path='deployment/:page'
                            element={<DeploymentPage/>}
                        />
                        <Route
                            path='deployment/production/:deploymentId'
                            element={<DeploymentError environment={"production"}/>}
                        />
                        <Route
                            path='deployment/test-instance/:deploymentId'
                            element={<DeploymentError environment={"test-instance"}/>}
                        />
                        <Route
                            path='deployment/versions/:version'
                            element={<VersionDetails/>}
                        />
                    </Route>
                </Route>
            </Route>

            {/* Catch all */}
            <Route path='*' element={<Error/>}/>
            <Route path='/unauthorized' element={<Unauthorized/>}/>
        </Routes>
    )
}
export default AppRoutes
