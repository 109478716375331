import {t} from 'i18next'
import {useParams} from 'react-router-dom'

import Pill from 'src/components/parts/Pill'
import BackButton from 'src/components/parts/BackButton'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import PortfolioChangesTable from 'src/components/parts/PortfolioChangesTable'
import {useVersionPortfolioChanges} from './hooks'

const VersionDetails = () => {
    const {version} = useParams()
    const {
        pageInfo,
        portfolioChanges,
        portfolioChangesData,
        isLoadingPortfolioChanges,
        setCurrentPage,
        handleResetPage,
    } = useVersionPortfolioChanges(version)

    if (isLoadingPortfolioChanges || !portfolioChangesData) {
        return <div className="flex justify-center items-center h-screen">
            <LoadingSpinner/>
        </div>
    }

    if (!version) {
        return <div className="flex justify-center items-center h-screen">
            <span className="text-lg text-primary-dark">{t('version-not-found', {ns: 'deployment'})}</span>
        </div>
    }

    return (
        <div className='flex flex-col w-full gap-4 text-primary-dark'>
            <div className='flex items-center gap-4'>
                                <BackButton />
                <span className='font-bold text-2xl'>
                    {t('version', {ns: 'deployment'})} {version}
                </span>
            </div>
            <div className='flex gap-2 text-sm font-semibold text-primary-dark'>
                <span>{t('changes-in-version', {ns: 'deployment'})}</span>
                <Pill
                    className='min-w-7 text-primary-dark bg-neutral-400'
                    label={portfolioChangesData?.pagingInfo.totalCount}
                />
            </div>
            <PortfolioChangesTable
                portfolioChanges={portfolioChanges}
                pageInfo={pageInfo}
                handleResetPage={handleResetPage}
                setCurrentPage={setCurrentPage}
                version={version}
            />
        </div>
    )
}

export default VersionDetails
