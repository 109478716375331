import {z} from 'zod'
import moment from 'moment-timezone'
import toast from 'react-hot-toast'
import {QueryClient} from 'react-query'
import {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'

import {useAuthContext} from 'src/context/AuthProvider'
import ToastWidget from 'src/components/parts/ToastWidget'
import DeploymentApi from 'src/services/api/deploymentApi'
import PortfolioApi, {PageInfo} from 'src/services/api/portfolioApi'
import {usePortfolioChangesQuery} from 'src/services/hooks/usePortfolioOverviewChangesDataQuery'

export const useHooks = () => {
    const queryClient = new QueryClient()
    const navigate = useNavigate()
    const {deployToProduction, scheduleDeploymentToProduction} = PortfolioApi
    const {getProdDeployment} = DeploymentApi
    const {company} = useAuthContext()
    const companyId = company?.uuid

    const [isShowScheduledDeploymentModal, setIsShowScheduledDeploymentModal] =
        useState(false)
    const [isShowImmediateDeploymentModal, setIsShowImmediateDeploymentModal] =
        useState(false)
    const [isShowDeploymentSelectionModal, setIsShowDeploymentSelectionModal] = 
        useState(false)
    const [isDeploying, setIsDeploying] = useState(false)
    const [currentPage, setCurrentPage] = useState<number | undefined>(
        undefined,
    )
    const [perPage, setPerPage] = useState<number | undefined>(undefined)
    const [pageInfo, setPageInfo] = useState<PageInfo | undefined>(undefined)

    const {portfolioChangesData, refetchPortfolioChangesData} =
        usePortfolioChangesQuery({
            companyId,
            page: currentPage,
            perPage,
        })

    const portfolioChanges = portfolioChangesData?.portfolioChangeList || []

    /**
     * Deploy Portfolio
     */
    const deploymentSchema = z.object({
        deploymentType: z.string(),
        deploySchedule: z.string(),
        dateInputName: z.string().optional().nullable(),
        timeInputName: z.string().optional().nullable(),
    })

    type DeploymentSchema = z.infer<typeof deploymentSchema>

    const {
        register: deploymentRegister,
        handleSubmit: deploymentSubmit,
        control: deploymentControl,
        setValue: setDeploymentValue,
    } = useForm<DeploymentSchema>({
        resolver: zodResolver(deploymentSchema),
    })

    const {deploymentType, dateInputName, timeInputName} = useWatch({
        control: deploymentControl,
    })

    useEffect(() => {
        if (deploymentType === 'deploy-now') {
            setDeploymentValue('timeInputName', null)
            setDeploymentValue('dateInputName', null)
            setDeploymentValue(
                'deploySchedule',
                moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            )
        } else {
            if (dateInputName && timeInputName) {
                const combinedDateTime = `${dateInputName} ${timeInputName}`
                // @ts-ignore
                let timezoneOffset = new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1];
                setDeploymentValue(
                    'deploySchedule',
                    moment(combinedDateTime + " " + timezoneOffset).utc().format('YYYY-MM-DDTHH:mm:ss.SSS'),
                )
            }
        }
    }, [deploymentType, dateInputName, timeInputName, setDeploymentValue])

    const deploy = async (data: DeploymentSchema) => {
        const {deploySchedule} = data
        setIsDeploying(true)

        if (!companyId) return

        if (deploymentType === 'deploy-now') {
            deployNow({
                companyId,
                deploySchedule,
            })
        } else {
            deployLater({
                companyId,
                deploySchedule,
            })
        }
    }

    const deployNow = async ({
                                 companyId,
                                 deploySchedule,
                             }: {
        companyId: string
        deploySchedule: string
    }) => {
        try {
            const deploymentId = await deployToProduction({
                companyId,
                scheduledTime: deploySchedule,
            })

            setIsShowDeploymentSelectionModal(false)
            setIsShowImmediateDeploymentModal(true)
            setIsDeploying(false)
            fetchNewDeployment({
                companyId,
                deploymentId,
            })
        } catch (error: any) {
            toast.dismiss()
            toast.error(
                (t) => (
                    <ToastWidget
                        label='Deploying to Production' //TODO
                        message={error.message}
                        handleClose={() => toast.dismiss(t.id)}
                    />
                ),
                {position: 'bottom-right'},
            )
        }
    }

    const deployLater = async ({
                                   companyId,
                                   deploySchedule,
                               }: {
        companyId: string
        deploySchedule: string
    }) => {
        try {
            await scheduleDeploymentToProduction({
                companyId,
                scheduledTime: deploySchedule,
            })
            setIsShowDeploymentSelectionModal(false)
            setIsShowScheduledDeploymentModal(true)
            setIsDeploying(false)
        } catch (error: any) {
            toast.dismiss()
            toast.error(
                (t) => (
                    <ToastWidget
                        label='Deploying to Production' //TODO
                        message={error.message}
                        handleClose={() => toast.dismiss(t.id)}
                    />
                ),
                {position: 'bottom-right'},
            )
        }
    }

    const fetchNewDeployment = async ({
                                          companyId,
                                          deploymentId,
                                      }: {
        companyId: string
        deploymentId: string
    }) => {
        try {
            const deployment = await getProdDeployment({
                companyId,
                deploymentId,
            })

            if (deployment.deploymentStatus === 'Pending') {
                throw new Error('Deployment is in progress')
            } else if (deployment.deploymentStatus === 'Success') {
                toast.dismiss()
                toast.success(
                    (t) => (
                        <ToastWidget
                            label='Production Deployment'
                            message={
                                <div className='flex flex-col'>
                                    <span>Deployed</span> {/* TODO */}
                                    <Link
                                        onClick={() => toast.dismiss(t.id)}
                                        to='/deployment/production'
                                        className='underline text-info'
                                    >
                                        View details
                                    </Link>
                                </div>
                            }
                            handleClose={() => toast.dismiss(t.id)}
                        />
                    ),
                    {position: 'bottom-right', duration: Infinity},
                )
                setIsShowImmediateDeploymentModal(false)
                queryClient.invalidateQueries('prodDeployments')
            } else {
                toast.dismiss()
                toast.error(
                    (t) => (
                        <ToastWidget
                            label='Production Deployment'
                            message={
                                <div className='flex flex-col'>
                                    <span>Failed</span> {/* TODO */}
                                    <Link
                                        onClick={() => toast.dismiss(t.id)}
                                        to='/deployment/production'
                                        className='underline text-info'
                                    >
                                        View details
                                    </Link>
                                </div>
                            }
                            handleClose={() => toast.dismiss(t.id)}
                        />
                    ),
                    {position: 'bottom-right', duration: Infinity},
                )
                setIsShowImmediateDeploymentModal(false)
                queryClient.invalidateQueries('prodDeployments')
            }
        } catch (error) {
            setTimeout(() => {
                fetchNewDeployment({companyId, deploymentId})
            }, 5000)
        }
    }

    const minimizeAndNotify = async () => {
        toast.loading(
            (t) => (
                <ToastWidget
                    label='Production Deployment'
                    message='In progress'
                    handleClose={() => toast.dismiss(t.id)}
                />
            ),
            {position: 'bottom-right'},
        )
        setIsShowImmediateDeploymentModal(false)
        navigate('/deployment/production')
    }

    /**
     * Pagination
     */
    useEffect(() => {
        if (!portfolioChangesData?.pagingInfo) return

        const {currentPage, pageCount, perPage, totalCount} =
            portfolioChangesData?.pagingInfo

        setPageInfo({
            currentPage,
            perPage,
            totalCount,
            pageCount,
        })
    }, [portfolioChangesData])

    useEffect(() => {
        refetchPortfolioChangesData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perPage, currentPage])

    const handleResetPage = (perPage: number) => {
        setCurrentPage(1)
        setPerPage(perPage)
    }

    return {
        pageInfo,
        dateInputName,
        timeInputName,
        deploymentType,
        portfolioChanges,
        portfolioChangesData,
        isDeploying,
        isShowScheduledDeploymentModal,
        isShowImmediateDeploymentModal,
        isShowDeploymentSelectionModal,
        deploy,
        navigate,
        setCurrentPage,
        handleResetPage,
        deploymentSubmit,
        minimizeAndNotify,
        deploymentRegister,
        setIsShowScheduledDeploymentModal,
        setIsShowDeploymentSelectionModal,
    }
}
