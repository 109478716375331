import {t} from 'i18next'
import moment from 'moment-timezone'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {PageInfo} from 'src/services/api/portfolioApi'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import {useAuthContext} from 'src/context/AuthProvider'
import Paginator from 'src/components/templates/Paginator'
import {useReferenceDataQuery} from 'src/services/hooks/useReferenceDataQuery'

export const ReferenceVersionsTable = () => {
    const {company} = useAuthContext()
    const companyId = company?.uuid

    const isSupplierCompany = company?.type === 'SUPPLIER'

    const [currentPage, setCurrentPage] = useState<number | undefined>(
        undefined,
    )
    const [perPage, setPerPage] = useState<number | undefined>(undefined)
    const [pageInfo, setPageInfo] = useState<PageInfo | undefined>(undefined)

    const {
        referenceDataVersionData,
        refetchReferenceDataVersionData,
        isLoadingReferenceDataVersions
    } = useReferenceDataQuery({
            companyId,
            page: currentPage,
            perPage,
            enabled: isSupplierCompany,
        })

    useEffect(() => {
        if (!referenceDataVersionData?.pagingInfo) return

        const {currentPage, pageCount, perPage, totalCount} =
            referenceDataVersionData?.pagingInfo

        setPageInfo({
            currentPage,
            perPage,
            totalCount,
            pageCount,
        })
    }, [referenceDataVersionData])

    useEffect(() => {
        isSupplierCompany && refetchReferenceDataVersionData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perPage, currentPage])

    if (isLoadingReferenceDataVersions || !referenceDataVersionData) {
        return <LoadingSpinner/>
    }

    const referenceDataVersions =
        referenceDataVersionData?.dataVersionList || []

    const handleResetPage = (perPage: number) => {
        setCurrentPage(1)
        setPerPage(perPage)
    }

    const VersionTableRow = ({
                                 version,
                                 dateValue,
                             }: {
        version: string
        dateValue: string
    }) => (
        <tr className='group border-neutral-200 border-b-[1px] hover:bg-white'>
            <td className='px-8 py-4 whitespace-nowrap'>
                <Link
                    to={`/deployment/versions/${version}`}
                    className="text-primary-dark hover:underline"
                >
                    {version}
                </Link>
            </td>
            <td className='px-8 py-4 whitespace-nowrap'>
                {dateValue ?
                    moment(dateValue).format('DD.MM.YYYY, h:mm A')
                    : '-'}
            </td>
        </tr>
    )

    return (
        <div>
            <div className='table-container max-h-[calc(100vh-240px)] overflow-y-auto'>
                <table className='w-full text-sm text-left bg-neutral-50'>
                    <thead className='sticky top-0 text-sm uppercase bg-neutral-200'>
                    <tr>
                        <th
                            scope='col'
                            className='w-40 px-8 py-5 text-gray-700 whitespace-nowrap'
                        >
                            {t('version', {ns: 'deployment'})}
                        </th>
                        <th
                            scope='col'
                            className='px-8 py-5 text-gray-700 whitespace-nowrap'
                        >
                            {company?.type === 'SUPPLIER'
                                ? t("publishDate", {ns: 'reference'})
                                : t("deployDate", {ns: 'deployment'})}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {referenceDataVersions.map((version, index) => (
                        <VersionTableRow
                            key={index}
                            version={version.version}
                            dateValue={version.publishDate}
                        />
                    ))}
                    </tbody>
                </table>
            </div>
            {pageInfo && (
                <Paginator
                    pageInfo={pageInfo}
                    handleSetPerPage={handleResetPage}
                    handleSetCurrentPage={setCurrentPage}
                />
            )}
        </div>
    )
}
