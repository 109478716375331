import React from 'react';

type SwitchProps = {
    isSelected: boolean;
    handleSelect: () => void;
    isDistributor?: boolean;
    disabled?: boolean;
};

const Switch: React.FC<SwitchProps> = ({ isSelected, handleSelect, isDistributor = false, disabled = false }) => {
        const isReadOnly = isDistributor || disabled;
    const ariaLabel = isReadOnly ? 'This switch is read-only' : 'Toggle switch';

    return (
        <button
                        onClick={isReadOnly ? (e) => e.preventDefault() : handleSelect}
            aria-label={ariaLabel}
            className={`relative w-10 h-6 rounded-full transition-all ease-in-out duration-300 ${
                isReadOnly ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            } ${isSelected ? 'bg-primary-green/80' : 'bg-neutral-400'}`}
            disabled={isReadOnly}
            aria-disabled={isReadOnly}
        >
            <span
                className={`absolute top-1/2 left-1/2 -translate-y-1/2 h-4 w-4 rounded-full transition-all ease-in-out duration-300 ${
                    isSelected ? 'bg-white ' : '-translate-x-full bg-neutral-500'
                }`}
            ></span>
        </button>
    );
};

export default Switch;